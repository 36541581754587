section.s-heading {
	header {
        @include grid(48);

        h1 {
            text-align: center;
            margin-bottom: $spacing / 2;
        }
        .contact-info {
            text-align: center;
        }
    }
}
