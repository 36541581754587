div.menu {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: block;
    background-color: #f1f1f1;
    z-index: 8888;
    visibility:hidden;
    opacity:0;
    @include transition(opacity ,300ms, ease);

    &.is-active {
        visibility:visible;
        opacity:1;
        @include transition(opacity ,300ms, ease);
    }

    .inner {
        position: absolute;
        left: 50%;
        top: 45%;
        transform: translateX(-50%) translateY(-50%);

		@media (min-width: $breakpoint-20) {
			top: 50%;
		}

        ul {
            margin: 0;
            list-style-type: none;
            li {
                width: 200px;
                text-align: center;

                a{
                    width: 200px;
                    display: block;
                    transform: translateY(0);
                    margin: 30px auto;
                    font-size: 1em;
                    font-family: $font-family-heading;
                    color: $color-secondary-50;
                    text-align: center;
                    @include transition(all ,300ms, ease);

                    hr {
                        width: 100px;
                        margin: 0.3rem 50px;
                    }
                    span {
                        display: block;
                        width: 100%;

                        &.title {
                            text-transform: uppercase;
                        }

                        &.sub {
                            font-size: 0.8rem;
                            font-family: $font-family-body;
                        }
                    }

                    &:hover {
                        color: $color-primary-70;
                        @include transition(all ,300ms, ease);
                    }
                }
            }
        }
    }

    .footer {
        @include grid(48);
        position: absolute;
        height: 40px;
        bottom: 55px;
        left: 0;
        text-align: center;
        @include transition(all ,300ms, ease);

		@media (min-width: $breakpoint-20) {
			bottom: 0;
		}

        i {
            margin-left: $spacing;
            font-size: 1.5em;
            color: $color-neutral-30;
			@include transition(all ,300ms, ease);

            &.fa-facebook-square {
                margin-left: 0;

                &:hover {
                    color: #3b5998;
                    @include transition(all ,300ms, ease);
                }
            }

            &.fa-instagram {

                &:hover {
                    color: #fcaf45;
                    @include transition(all ,300ms, ease);
                }
            }

            &.fa-linkedin-square {

                &:hover {
                    color: #0077b5;
                    @include transition(all ,300ms, ease);
                }
            }

			&.fa-twitter-square {

				&:hover {
					color: #4AB3F4;
					@include transition(all ,300ms, ease);
				}
			}
        }
    }


    @media (min-width: $breakpoint-20) {
        height: 100%;
        width: 200px;
        visibility:visible;
        opacity:1;
    }
}

.hamburger {
    position: absolute;
	top: 0;
	left: 0;
    z-index: 9999;
	background-color: rgba(255,255,255,0.7) !important;
	outline: none;
	border: none;
	-moz-appearance: none;
	-webkit-appearance: none;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	&.is-active {
		position: fixed;
		background-color: rgba(255,255,255,0) !important;
	}

    @media (min-width: $breakpoint-20) {
        visibility:hidden;
        opacity:0;
		position: absolute;
		top: -100px;

    }
}
