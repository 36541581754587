section.s-video-background {
	margin: 0;
	padding: 0;

	.l-container {
		margin: 0;
		padding: 0;
	}

	.overlay {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translateX(-50%) translateY(-50%);
		z-index: 2;

		text-align: center;

		h1 {
			text-transform: uppercase;
		}

		span.subtitle-typed {
			font-family: $font-family-heading;
			font-size: 1.5em;
			color: $color-heading;
		}

		a {
			font-weight: bold;
		}

		.typed-cursor{
			opacity: 1;
			animation: typedjsBlink 0.7s infinite;
			-webkit-animation: typedjsBlink 0.7s infinite;
			animation: typedjsBlink 0.7s infinite;
		}

		@keyframes typedjsBlink{
			50% { opacity: 0.0; }
		}

		@-webkit-keyframes typedjsBlink{
			0% { opacity: 1; }
			50% { opacity: 0.0; }
			100% { opacity: 1; }
		}

		.typed-fade-out{
			opacity: 0;
			transition: opacity .25s;
			-webkit-animation: 0;
			animation: 0;
		}
	}

	.video-wrapper {
		height: 100vh;
		width: 100%;
		position: relative;
		overflow: hidden;

		.video-background {
			position: absolute;
			transform: translateY(-50%);
			overflow: hidden;
			z-index: 1;
			height: 200%;
			opacity: 0.3;
		}
	}
}
