// =========== States

.is-hidden { display: none !important; }

.is-invisible { visibility: hidden !important; }

.is-text-left { text-align: left; }

.is-text-right { text-align: right; }

.is-text-center { text-align: center; }

.has-nowrap { white-space: nowrap; }

.is-left { float: left; }

.is-right { float: right; }
