.s-contact {
	height: 100vh;
	position: relative;

	.inner {
		width: 60%;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translateX(-50%) translateY(-50%);

		.block {
			@include grid(48);

			p {
				font-size: $font-size-base;
			}

			span {
				width: 100%;
				display: block;
				margin-bottom: 5px;

				&.label {

				}

				&.content {
					margin-bottom: $spacing;
				}
			}

			a {
				i {
					font-size: 1.25em;
					margin-right: 0.5em;
					color: $color-font;
					@include transition(all ,300ms, ease);

		            &.fa-facebook-square {
		                margin-left: 0;

		                &:hover {
		                    color: #3b5998;
		                    @include transition(all ,300ms, ease);
		                }
		            }

		            &.fa-instagram {

		                &:hover {
		                    color: #fcaf45;
		                    @include transition(all ,300ms, ease);
		                }
		            }

		            &.fa-linkedin-square {

		                &:hover {
		                    color: #0077b5;
		                    @include transition(all ,300ms, ease);
		                }
		            }

					&.fa-twitter-square {

						&:hover {
							color: #4AB3F4;
							@include transition(all ,300ms, ease);
						}
					}

					&.fa-whatsapp {

						&:hover {
							color: #25d366;
							@include transition(all ,300ms, ease);
						}
					}
				}
			}
		}
	}
}
