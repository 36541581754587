@mixin box-shadow($top, $left, $blur, $color) {
    -webkit-box-shadow: $top $left $blur $color;
    -moz-box-shadow: $top $left $blur $color;
    box-shadow: $top $left $blur $color;
  }

  @mixin transition($property, $duration, $type) {
  	-webkit-transition: $property $duration $type;
  	-moz-transition: $property $duration $type;
  	-ms-transition: $property $duration $type;
  	-o-transition: $property $duration $type;
  	transition: $property $duration $type;
  }
