// =========== Layout

// Get full width backgrounds around your content
.l-wrapper {

  &.is-10 {
    background-color: $color-neutral-10;
  }
}

// Build up your container to get your website centered
.l-container {
  margin: auto;
  max-width: $max-width;
  padding: 0;
  padding: $spacing $spacing 0 $spacing;
  @include grid-container;

  @media (min-width: $breakpoint-20) {
    padding: $spacing * 2;
    padding-bottom: $spacing;
  }

  &.is-full-width {
    max-width: none;
    margin: 0;
  }
}

// Other layout rules are almost always nested in the HTML of the l-container.
// You can also use .l-1 to .l-48 for full control.
.l-full {

  @media (min-width: $breakpoint-10) {
    @include grid(48, 1);
  }
}

.l-two-third {

  @media (min-width: $breakpoint-10) {
    @include grid(32, 1);
  }
}

.l-single {
  max-width: 36rem;
  margin: auto;
  clear: both;
}

.l-half {

  @media (min-width: $breakpoint-10) {
    @include grid(24, 1);
  }
}

.l-one-third {

  @media (min-width: $breakpoint-10) {
    @include grid(16, 1);
  }
}

.l-first {
  clear: left;
}

.l-left {

  @media (min-width: $breakpoint-10) {
    float: left;
  }
}

.l-right {

  @media (min-width: $breakpoint-10) {
    float: right;
  }
}
